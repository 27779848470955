html[data-theme='light'] {
  --primary-color: #716f81;
  --secondary-color: #b97a95;
  --text-color: #111111;
  --text-color-light: #eeeeee;
  --text-color-dark: #0c0c0c;
  --border-color: #c6c6c6;
  --background-color: #ffffff;
  --background-color-container: #f9f9f9;

  --background-color-tool: var(--background-color-container);
  --background-color-tools-header: #716f81ff;
  --background-color-tools-settings: darken(var(--background-color-tool), 5%);

  --background-color-table: var(--background-color);
  --background-color-table-striped: darken(var(--background-color), 5%);

  --red-color: #c24e4e;
  --red-color-background: lighten(var(--red-color), 40%);
  --orange-color: #c2944e;
  --green-color: #4ec254;

  --darkmode-toggle-background: #64425c;
  --darkmode-toggle-background-hover: #54374d;
}

html[data-theme='dark'] {
  --primary-color: #2d2d2d;
  --secondary-color: #944444;
  --text-color: #dcdcdc;
  --text-color-light: #e3e3e3;
  --text-color-dark: #0a0a0a;
  --background-color: #1d1d1d;
  --background-color-container: #383838;
  --border-color: #545454;

  --background-color-tool: var(--background-color-container);
  --background-color-tools-header: #773f3f;
  --background-color-tools-settings: lighten(var(--background-color-tool), 5%);

  --background-color-table: lighten(var(--background-color), 2%);
  --background-color-table-striped: lighten(var(--background-color), 5%);

  --red-color: #814242;
  --red-color-background: lighten(var(--red-color), 40%);
  --orange-color: #816f42;
  --green-color: #568142;

  --darkmode-toggle-background: #4b3434;
  --darkmode-toggle-background-hover: #573d3d;
}

$padding: 8px;
$padding-small: 4px;
$padding-large: 16px;
