@import '../../Variables';

.brand {
  width: 60px;
  height: 60px;
  object-fit: contain;
}

.site-header {
  position: relative;
  background-color: var(--primary-color);
  flex: 0 1 auto;

  .site-header__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;

    ul {
      list-style-type: none;
    }

    nav ul {
      margin: 0;
    }
  }
}

.site-header__middle {
  text-align: center;
}

.nav__wrapper {
  display: flex;
  padding-inline-start: unset;
  font-size: 1.2em;
}

.nav__item {
  a,
  button {
    display: block;
    padding: 1.5rem 1rem;

    transition: background-color, background-color 300ms;

    &.navbar__item-active {
      background-color: var(--secondary-color);
    }

    &:hover {
      background-color: var(--secondary-color);
    }
  }
}

.nav__toggle {
  display: none;
}
