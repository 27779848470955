@import '../../Variables';

.page .tools__container {
  .tools__side-bar__header {
    background-color: var(--background-color-tools-header);
    padding: $padding;
    color: var(--text-color-light);
  }

  .tools__tool-header-row {
    display: flex;
    border: 1px solid var(--background-color-tools-header);

    .tools__side-bar__header {
      border-right: 1px solid var(--background-color-tools-header);
    }
  }

  .tools__tool-row {
    display: flex;
    border: 1px solid var(--background-color-tools-header);
    background-color: var(--background-color-tool);

    .tools__side-bar {
      width: 25%;
      //border-right: 1px solid var(--border-color);
      border-right: 1px solid var(--background-color-tools-header);
      background-color: var(--background-color-tools-settings);

      .tools-settings-header {
        background-color: var(--background-color-tools-header);
        padding: 0.5rem 8%;
        color: var(--text-color-light);
      }

      .tools-settings,
      .beatmap-settings {
        padding: 0.5rem 8%;
      }
    }
  }

  .tools__tool-name {
    background-color: var(--background-color-tools-header);
    color: var(--text-color-light);
  }

  .tools_tool-container {
    width: 75%;
  }

  .tools__tool {
    background-color: var(--background-color-tool);
  }
}

.tools__tool-selector-row {
  background-color: var(--primary-color);
  border: 1px solid var(--secondary-color);

  .tools__tool-selector {
    width: 100%;
    display: flex;

    .tools__tool-selector-option {
      padding: 1rem 2%;
      font-size: 1.2rem;
      transition: background-color, box-shadow 300ms;

      &.active-tool,
      &:hover {
        box-shadow: inset 0 -5px 0 var(--secondary-color);
        //background-color: var(--secondary-color);
      }
    }
  }
}
