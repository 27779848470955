@import 'Variables';

* {
  //margin: unset;
  color: var(--text-color);
}

html,
body {
  height: 100%;
}

body {
  background-color: var(--background-color);
  overflow: scroll;
}

a,
button {
  cursor: pointer;
  background-color: unset;
  border: unset;
  text-decoration: none;
  padding: unset;
  color: var(--text-color-light);
}

.website__wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.wrapper {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  padding: $padding-large;
}

.content-wrapper {
  flex: 1;
}

.page {
  margin-top: $padding;
  margin-bottom: $padding;

  .page__header {
    background-color: var(--primary-color);
    color: var(--text-color-light);
    padding: $padding-small $padding-large;
    margin: unset;
  }

  .page__breadcrumb {
    background-color: var(--secondary-color);
    padding: $padding-small $padding-large;
    display: flex;

    .page__breadcrumb-item {
      &:not(:last-child) {
        text-decoration: underline;
      }

      &:not(:first-child) {
        padding-left: 4px;

        &::before {
          content: ' / ';
        }
      }
    }

    * {
      color: var(--text-color-light);
    }
  }

  .page__container {
    background-color: var(--background-color-container);
    //padding: $padding-small $padding-large;

    *.fake-bold {
      font-weight: normal;
      color: var(--text-color);
    }

    a,
    p {
      color: var(--text-color);
    }
  }
}

.row .note-col {
  //margin-top: unset;

  .note {
    margin-top: unset;
    padding: $padding;
    background-color: var(--background-color-tools-settings);
    border: 1px solid var(--background-color-tools-settings);
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
}

input[type='number'],
input[type='text'] {
  width: 100%;
  padding: $padding;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  box-sizing: border-box;
  color: var(--text-color-dark);

  &.form-input__error {
    border-color: var(--red-color);
    background-color: var(--red-color-background);
  }
}

.checkbox-input {
  display: flex;
  padding: 0.1rem 2%;
}

input[type='checkbox'] {
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background: var(--background-color-container);
  border-radius: 5px;
  position: relative;
  box-sizing: content-box;
  width: 1.3em;
  height: 1.3em;
  border: 1px solid var(--border-color);
  transition: all 0.2s linear;
  margin: $padding-small;
}

input[type='checkbox']:checked {
  background-color: var(--green-color);
  border: 1px solid var(--green-color);
}

input[type='checkbox']:focus {
  outline: 0 none;
  box-shadow: none;
}

table {
  border-spacing: unset;
  table-layout: fixed;
  width: 100%;

  td,
  th {
    border: 1px solid var(--border-color);
    padding: $padding;
    text-align: center;
  }

  th {
    padding-top: $padding;
    padding-bottom: $padding;
    background-color: var(--primary-color);
    color: var(--text-color-light);
  }

  tr {
    background-color: var(--background-color-table);

    &:nth-child(even) {
      background-color: var(--background-color-table-striped);
    }
  }
}

.logo {
  width: 50px;
  height: 50px;
  object-fit: contain;
  display: flex;
}

.content-wrapper img {
  height: auto;
  width: auto;
  max-width: 100%;
  max-height: 675px;
}

.footer {
  flex: 0 1 auto;
  background-color: var(--primary-color);

  button,
  a.button {
    cursor: pointer;
    margin: $padding;
    padding: $padding;
    border: unset;
    border-radius: 5px;
    transition: background-color 300ms;
    color: var(--text-color-light);
    background-color: var(--darkmode-toggle-background);

    &:hover {
      background-color: var(--darkmode-toggle-background-hover);
    }

    .button__container {
      .button__text {
        color: var(--text-color-light);
      }
    }
  }

  .footer__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;

    .footer-nav__wrapper {
      display: flex;
      padding-inline-start: unset;
      font-size: 1.2em;
    }

    .footer-nav__item {
      button,
      a {
        display: flex;
        margin: $padding-large;
      }
    }
  }
}
